<template>
  <div class="pay-succeed">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        等待支付系统返回
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="title" v-if="waitingStage == 2">
        未查询到支付记录
      </div>
      <div class="title" v-if="waitingStage == 0 || waitingStage == 1">
        支付中...
      </div>
      <div class="tool">
        <button class="btn disable" v-if="waitingStage == 0">等待 {{ seconds }} 秒</button>
        <button
          class="btn"
          :class="{ disable: btnActive == false }"
          v-if="waitingStage == 1"
          @click="onActiveQueryPayment"
        >
          刷新订单状态
        </button>
      </div>
      <div class="info-cs" v-if="waitingStage == 2">
        <div style="margin-bottom:20px">找客服</div>
        <div class="img">
          <img :src="contactImg" alt="" />
        </div>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
const COUNT_DOWN_SECOND = 6;
import { TouchEmulator } from "@/util/touch.js";
import csWidget from "@/components/csWidget.vue";
import footerMenu from "@/components/footerMenu.vue";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";
import { customerServiceApi } from "@/api/customerServiceApi.js";
import dayjs from "dayjs";
export default {
  components: {
    footerMenu,
    csWidget,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      contactImg: require("@/assets/images/aida-cs.png"),
      orderId: 0,
      isWaiting: true,
      clock: null,
      seconds: COUNT_DOWN_SECOND, // 倒计时6秒查询订单状态，如果是成功，跳转；否则 出现手动刷新按钮
      waitingStage: 0, // 0 是等待倒计时的stage，1是可以主动刷新一下，2是查不到订单已支付，联系客服
      btnActive: true,
      showCsWidget: false,

      weChat: {
        id: 0,
        title: "",
        text: "",
        weChat: {
          wechatId: "",
          url: "",
          backupWechatId: "",
        },
      },
    };
  },
  methods: {
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.orderId = this.$route.query.id;
      }
      if (this.orderId <= 0) {
        this.showCsWidget = true;
      }
      return this.orderId;
    },
    onGoBack() {
      //this.$router.go(-1);
      let url = "";
      if (process.env.NODE_ENV == "development") {
        url = "http://ckw.kaiweitalk.com/courseDetail?id=" + this.courseId;
      } else {
        url = "http://aida101.com/courseDetail?id=" + this.courseId;
      }
      if (url) {
        window.location.href = url;
      } else {
        this.$router.go(-1);
      }
    },
    onCountDown() {
      this.isWaiting = true;
      this.seconds = COUNT_DOWN_SECOND;
      this.clock = setInterval(() => {
        if (this.seconds <= 1) {
          clearInterval(this.clock);
          this.isWaiting = false;
          this.onQueryPayment();
        } else {
          this.seconds -= 1;
        }
      }, 1000);
    },
    onQueryPayment() {
      if (this.orderId <= 0) {
        console.log("order id <= 0", this.orderId);
        return;
      }
      orderApi
        .queryOrderPayment(this.orderId)
        .then((ret) => {
          const { code, msg, data } = ret;
          console.log("..", data, "#", data.status);
          if (code === 0 && data) {
            if (data.status === 1) {
              clearInterval(this.clock);
              this.clock = null;
              // todo 跳转到paySucceed
              this.$router.push({
                path: "/pay/paySucceed",
                query: {
                  id: this.orderId,
                },
              });
            } else {
              this.waitingStage = 1;
            }
          } else {
            this.showCsWidget = true;
          }
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    onActiveQueryPayment() {
      if (this.btnActive == false) {
        return;
      }
      if (this.orderId <= 0) {
        console.log("order id <= 0", this.orderId);
        return;
      }
      this.btnActive = false;
      orderApi
        .queryOrderPayment(this.orderId)
        .then((ret) => {
          const { code, msg, data } = ret;
          console.log("..", data, "#", data.status);
          if (code == 0 && data) {
            if (data.status == 1) {
              console.log("succeed");
              // todo 跳转到paySucceed
              this.$router.push({
                path: "/pay/paySucceed",
                query: {
                  id: this.orderId,
                },
              });
            } else {
              // todo 允许用户主动查询
              this.waitingStage = 2;
            }
          } else {
            this.showCsWidget = true;
          }
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    getBanner() {
      customerServiceApi.get(1002).then((ret) => {
        if (ret.code == 0 && ret.data) {
          this.weChat = ret.data;
        }
      });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    this.getBanner();
    const orderId = this.getId();
    this.orderId = orderId;

    this.onCountDown();

    this.listenOnCloseCsWidget();

    this.$once("hook:beforeDestory", function() {
      clearInterval(this.clock);
      this.clock = null;
    });
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.pay-succeed {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    // flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 10px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    overflow-y: scroll;
    .phone-and-date {
      margin: 5px 0px;
      font-size: 10px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.2px;
    }
    .title {
      margin: 30px 0px;
      font-size: 22px;
      color: #323233;
      letter-spacing: 0.49px;
      font-weight: 600;
      .price {
        font-size: 26px;
        color: #f86e00;
        letter-spacing: 0.58px;
        line-height: 30px;
        .yuan {
          font-size: 20px;
          margin: 0 8px;
        }
      }
    }
    .tool {
      .btn {
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        background: #ff8423;
        box-shadow: 0 2px 4px 0 #ff8423;
        border-radius: 16px;
        border: none;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        width: 120px;
        height: 42px;
        margin: 13px 17px 17px 0;
      }
      .disable {
        background: #c7c7c7;
        box-shadow: 0 2px 4px 0 #c7c7c7;
      }
    }
    .info-cs {
      font-weight: 500;
      font-size: 16px;
      color: #363637;
      letter-spacing: 1.5px;
      text-align: center;
      margin: 0px 50px;
    }
  }
  .footer {
  }
}
</style>
